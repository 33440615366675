export const PermissionUserView = 'user:view';
export const PermissionUserEdit = 'user:edit';
export const PermissionUserDelete = 'user:delete';

export const PermissionRoleView = 'role:view';
export const PermissionRoleEdit = 'role:edit';
export const PermissionRoleDelete = 'role:delete';

export const ExpertUserPermissionConfigView = 'expertUser:config:view';
export const ExpertUserPermissionConfigEdit = 'expertUser:config:edit';

// 服务评价设置
export const ExpertUserPermissionEvaluationSettingView =
  'expertUser:evaluation_setting:view';
export const ExpertUserPermissionEvaluationSettingEdit =
  'expertUser:evaluation_setting:edit';
export const ExpertUserPermissionEvaluationSettingDelete =
  'expertUser:evaluation_setting:delete';

// 资格证书设置
export const ExpertUserPermissionCertificateSettingView =
  'expertUser:certificate_setting:view';
export const ExpertUserPermissionCertificateSettingEdit =
  'expertUser:certificate_setting:edit';
export const ExpertUserPermissionCertificateSettingDelete =
  'expertUser:certificate_setting:delete';

// 专家领域设置
export const ExpertUserPermissionProfessionalFieldSettingView =
  'expertUser:professional_field_setting:view';
export const ExpertUserPermissionProfessionalFieldSettingEdit =
  'expertUser:professional_field_setting:edit';
export const ExpertUserPermissionProfessionalFieldSettingDelete =
  'expertUser:professional_field_setting:delete';

// 邀请资料设置
export const ExpertUserPermissionInviteFieldSettingView =
  'expertUser:invite_field_setting:view';
export const ExpertUserPermissionInviteFieldSettingEdit =
  'expertUser:invite_field_setting:edit';
export const ExpertUserPermissionInviteFieldSettingDelete =
  'expertUser:invite_field_setting:delete';

// 专家管理
export const ExpertUserPermissionExpertView = 'expertUser:expert:view';
export const ExpertUserPermissionExpertEdit = 'expertUser:expert:edit';
export const ExpertUserPermissionExpertAudit = 'expertUser:expert:audit';

// 邀请管理
export const ExpertUserPermissionInviteView = 'expertUser:invite:view';
export const ExpertUserPermissionInviteEdit = 'expertUser:invite:edit';
export const ExpertUserPermissionInviteDelete = 'expertUser:invite:delete';
